/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/layout/Layout'
import NavLink from 'components/shared/NavLink'

// Elements
import TitlePrimary from 'components/elements/Titles/TitlePrimary'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled from 'styled-components'
import Levenshtein from 'js-levenshtein'

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  border-radius: 25px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  & a {
    color: ${({ theme }) => theme.color.text};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.color.text};
      text-decoration: underline;
      opacity: 0.8;
    }
  }
`

const Links = styled.div`
  & p {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const Or = styled.div`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

interface Props {
  location: any
}

const WhiteBlock = styled.div``

const NotFoundPage: React.FC<Props> = ({ location }) => {
  // @ts-ignore
  const query = useStaticQuery<GatsbyTypes.notfoundQuery>(graphql`
    query notfound {
      allWpPage {
        nodes {
          title
          uri
        }
      }
    }
  `)

  const [list, setList] = React.useState([])

  React.useEffect(() => {
    const levenshteinList: any = []

    query.allWpPage.nodes.forEach((n: any) => {
      levenshteinList.push({
        uri: n.uri,
        title: n.title,
        score: Levenshtein(location.pathname, n.uri),
      })
    })

    setList(levenshteinList)
  }, [])

  return (
    <Layout>
      <div className="bg-backgroundAlt pt-lg-5">
        <div className="container py-lg-5 py-3">
          <div className="row justify-content-center">
            <div className="col-lg-8 py-lg-5 py-4 my-5">
              <WhiteBlock className="text-text">
                <TitlePrimary as="h1">
                  <strong>Oops!</strong>
                </TitlePrimary>
                <TitlePrimary as="h3" className="mt-4">
                  <strong>
                    Het lijkt erop dat deze pagina niet (meer) bestaat
                  </strong>
                </TitlePrimary>
              </WhiteBlock>

              <Links className="mt-5">
                <ButtonPrimary to="/">Ga naar home</ButtonPrimary>
                <Or className="mt-4">Of</Or>
                <Block className="mt-4 pt-4 px-4">
                  {list
                    .sort((a: any, b: any) => a.score - b.score)
                    .map((n: any, index: number) => (
                      <div key={index} className="me-4 mb-4 d-inline-block">
                        <NavLink to={n.uri}>{n.title}</NavLink>
                      </div>
                    ))}
                </Block>
              </Links>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
